import React, { Component } from 'react';
import "../styles/Services.css"

class Services extends Component {
    render() { 
        return (
            <div id="Services" className="container">
                <div className="row text-center">
                    <div className="col-md-12">
                        <h1 id="ServiceTitle" className="mt-5">Cleaning Services</h1>
                        <p className="services-title mx-auto"></p>
                        <p>
                        For over 20 years we have been bringing our brand of SUNSHINE CLEANING 
                        to the golden state. We offer professional cleaning services that will leave 
                        you with an impeccably clean and organized home or office. Our main goal is for 
                        our clients to feel comfortable in their newly fresh and clean environment.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Services;