import React, { Component } from 'react';
import { Fragment } from 'react';
import FloatButton from '../components/FloatButton';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Location from '../components/Location';
import Navbar from '../components/Navbar';
import NavbarItem from '../components/NavbarItem';
import WhyChoose from '../components/WhyChoose';
import Description from '../ServicesComponents/Description';
import Gallery from '../ServicesComponents/Gallery';
import Videos from '../ServicesComponents/Videos';


const des = 
<p>
    <p>
        Sunshine Clean All can help restore and maintain the original beauty of your carpet with award winning professional carpet cleaning.
    </p>
    <p>
        Carpet cleaning and caring are important because they add beauty and pleasure to home environments. But the daily use of them, the continuous traffic of people and the activity on them cause their appearance level to decrease.
    </p>
    <p>
        SUNSHINE CLEANING ALL provides professional, effective and safe carpet cleaning services. Our service is 100% safe for all types of carpet, regardless of the different manufacturing materials. We use modern methods and equipment to your benefit.
    </p>
</p>
;

const NavbarItems = (
    <Fragment>
        <NavbarItem link={"#Offers"} item={"Offers"}/>
        <NavbarItem link={"#Gallery"} item={"Gallery"}/>
        <NavbarItem link={"#Location"} item={"Contact Info"}/>
    </Fragment>    
);

class CarpetCleaning extends Component {
    render() {
        return (
            <div>
                <Navbar NavbarItem={ NavbarItems }/>
                <Header/>
                <Description title={"Steam carpet and furniture cleaning"} url={"https://www.youtube.com/embed/ieCNeN8Aztc"} description={des}/>
                <FloatButton/>
                <WhyChoose/>
                <div className="container-fluid p-3 bg-light">
                    <h1 id="Gallery" className="text-center mt-4" style = {{color: '#00587a',fontSize: 45}}>Gallery</h1>
                    <div className="row">
                        <Gallery imagen={"../images/OfficeImages/office1.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office2.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office3.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office4.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office5.jpg"}/>
                    </div>
                </div>
                <div className="container-fluid">
                    <h1 id="Gallery" className="text-center mt-4 mb-4" style = {{color: '#00587a',fontSize: 45}}>Video Gallery</h1>
                    <Videos/>
                </div>
                <Location/>
                <Footer/>
            </div>
        );
    }
}

export default CarpetCleaning;