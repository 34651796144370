import React, { Component } from 'react';
import { Fragment } from 'react';
import FloatButton from '../components/FloatButton';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Location from '../components/Location';
import Navbar from '../components/Navbar';
import NavbarItem from '../components/NavbarItem';
import WhyChoose from '../components/WhyChoose';
import Description from '../ServicesComponents/Description';
import Gallery from '../ServicesComponents/Gallery';
import Videos from '../ServicesComponents/Videos';


const des = 
<p>
    <p>
        Take advantage of our most popular cleaning package. All your bathrooms, bedrooms and hallways get cleaned as well as your kitchen and living room, with Sunshine Clean All, it’s a worry-free service.
    </p>
    <p>
        If you’re in need of home cleaning, apartment cleaning, or a maid service, we’re simply the best, most convenient home cleaning service out there. We know you want the cheapest house cleaning available while still having the confidence that you will receive a cleaner who is thorough and professional, with keen attention to detail.
    </p>
    <p>
        When you sign up for a Sunshine house cleaning plan, we aim to offer you just that. And Sunshine Cleaning helps schedule your recurring cleanings automatically for you, so you can focus on the other things in your life.
    </p>
</p>
;


const NavbarItems = (
    <Fragment>
        <NavbarItem link={"#Offers"} item={"Offers"}/>
        <NavbarItem link={"#Gallery"} item={"Gallery"}/>
        <NavbarItem link={"#Location"} item={"Contact Info"}/>
    </Fragment>    
);



class HouseCleaning extends Component {
    render() {
        return (
            <div>
                <Navbar NavbarItem={ NavbarItems }/>
                <Header/>
                <Description title={"House Cleaning"} url={"https://www.youtube.com/embed/KpvkPnfoHms"} description={des}/>
                <FloatButton/>
                <WhyChoose/>
                <div className="container-fluid p-3 bg-light">
                    <h1 id="Gallery" className="text-center mt-4" style = {{color: '#00587a',fontSize: 45}}>Gallery</h1>
                    <div className="row">
                        <Gallery imagen={"../images/HouseCleaning/house1.jpg"}/>
                        <Gallery imagen={"../images/HouseCleaning/house2.jpg"}/>
                        <Gallery imagen={"../images/HouseCleaning/house3.jpg"}/>
                    </div>
                </div>
                <div className="container-fluid">
                    <h1 id="Gallery" className="text-center mt-4 mb-4" style = {{color: '#00587a',fontSize: 45}}>Video Gallery</h1>
                    <Videos/>
                </div>
                <Location/>
                <Footer/>
            </div>
        );
    }
}

export default HouseCleaning;