import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if ((this.props.location !== prevProps.location)) {
			window.scrollTo(800, 800);
		}
	}

	render() {
		return <React.Fragment />
	}
}

export default withRouter(ScrollToTop)