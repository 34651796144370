import React, { Component } from 'react';
import '../styles/Gallery.css'

class Gallery extends Component {
    render() { 
        return (
            <div id="Gallery" className="card image-card">
                <img src={this.props.imagen} className="card-img-top card_img" alt="gallery foto"/>
            </div>
        );
    }
}
 
export default Gallery;