import React, { Component } from 'react';
import { Fragment } from 'react';
import FloatButton from '../components/FloatButton';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Location from '../components/Location';
import Navbar from '../components/Navbar';
import NavbarItem from '../components/NavbarItem';
import WhyChoose from '../components/WhyChoose';
import Description from '../ServicesComponents/Description';
import Gallery from '../ServicesComponents/Gallery';
import Videos from '../ServicesComponents/Videos';


const des = <p>
                <p>
                Sunshine Clean all offers commercial and residential window cleaning services. Our crew has the knowledge and experience to confidently tackle any size window-cleaning need. Trust us to tackle your most difficult window cleaning jobs.
                </p>
                <p>
                    We offer you cleaning services for:
                </p>
                <ul>
                    <li>
                        Glasses
                    </li>
                    <li>
                        Windows
                    </li>
                    <li>
                        Sliding windows or doors
                    </li>
                    <li>
                        Aluminum frames
                    </li>
                    <li>
                        Movable vane type glass
                    </li>
                    <li>
                        Glass or polycarbonate domes
                    </li>
                </ul>
                We offer programs to regularly maintain both the interior and exterior windows of your building or office. We guarantee your windows, frames and ledges will be left clean and streak-free. It’s amazing how sparkling clean windows can have a positive affect on your business or home environment.
            </p>
            
;

const NavbarItems = (
    <Fragment>
        <NavbarItem link={"#Offers"} item={"Offers"}/>
        <NavbarItem link={"#Gallery"} item={"Gallery"}/>
        <NavbarItem link={"#Location"} item={"Contact Info"}/>
    </Fragment>   
);

class WindowCleaning extends Component {
    render() { 
        return (
            <div>
                <Navbar NavbarItem={ NavbarItems }/>
                <Header/>
                <Description title={"Window Cleaning"} url={"https://www.youtube.com/embed/r16RdGM4HxI"} description={des}/>
                <FloatButton/>
                <WhyChoose/>
                <div className="container-fluid p-3 bg-light">
                    <h1 id="Gallery" className="text-center mt-4" style = {{color: '#00587a',fontSize: 45}}>Gallery</h1>
                    <div className="row">
                        <Gallery imagen={"../images/WindowCleaning/window1.jpg"}/>
                        <Gallery imagen={"../images/WindowCleaning/window2.jpg"}/>
                    </div>
                </div>
                <div className="container-fluid">
                    <h1 id="Gallery" className="text-center mt-4 mb-4" style = {{color: '#00587a',fontSize: 45}}>Video Gallery</h1>
                    <Videos/>
                </div>
                <Location/>
                <Footer/>
            </div>
           
        );
    }
}
 
export default WindowCleaning;