import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 text-center p-2 bg-info text-white">
                        Copyright © 2020 Sunshine Cleaning All
                    </div>
                </div>
            </div>
            
        );
    }
}

export default Footer;