import React, { Component } from 'react';

class NavbarItem extends Component {
    render() { 
        return (
            <li className="nav-item">
                <a className="nav-link" href={this.props.link}>{this.props.item}</a>
            </li>
        );
    }
}
 
export default NavbarItem;