import React, { Component } from 'react';

class IconText extends Component {
    render() { 
        return (
            <div className="d-flex">
                <i className={this.props.icon}></i>
                <h6 className = "ml-2">{this.props.text}</h6>
            </div>
        );
    }
}
 
export default IconText;