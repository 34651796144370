import React, { Component } from 'react';
import { Fragment } from 'react';
import FloatButton from '../components/FloatButton';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Location from '../components/Location';
import Navbar from '../components/Navbar';
import NavbarItem from '../components/NavbarItem';
import WhyChoose from '../components/WhyChoose';
import Description from '../ServicesComponents/Description';
import Gallery from '../ServicesComponents/Gallery';
import Videos from '../ServicesComponents/Videos';



const des = <p>
                <p>
                    You should consider the professional pressure washing services of Sunshine Clean All. This service can quickly and effectively remove built-up dirt, sediment, and other debris. This not only helps the areas look beautiful, but it can also reduce wear and tear by getting rid of mold, algae, chalk, insects, weeds, and more. Such build-up will, over time, break down and cause damage to wood/masonry and degrade paint/stains.
                </p>
                <p>
                    Pressure washing offers the following benefits:
                </p>
                <ul>
                    <li>
                        It can clean and brighten fences, play equipment, and more
                    </li>
                    <li>
                        It can remove stains from your drive and walkways
                    </li>
                    <li>
                        It can make your home more sanitary for children
                    </li>
                    <li>
                        It can prepare an area for a painting or staining project
                    </li>
                    <li>
                        It can remove plants growing where they shouldn’t
                    </li>
                </ul>
            </p>
            
;

const NavbarItems = (
    <Fragment>
        <NavbarItem link={"#Offers"} item={"Offers"}/>
        <NavbarItem link={"#Gallery"} item={"Gallery"}/>
        <NavbarItem link={"#Location"} item={"Contact Info"}/>
    </Fragment>    
);

class PressureWashing extends Component {
    render() {
        return (
            <div>
                <Navbar NavbarItem={ NavbarItems }/>
                <Header/>
                <Description title={"Pressure Washing"} url={"https://www.youtube.com/embed/wtfKMkcdhPM"} description={des}/>
                <FloatButton/>
                <WhyChoose/>
                <div className="container-fluid p-3 bg-light">
                    <h1 id="Gallery" className="text-center mt-4" style = {{color: '#00587a',fontSize: 45}}>Gallery</h1>
                    <div className="row">
                        <Gallery imagen={"../images/OfficeImages/office1.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office2.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office3.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office4.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office5.jpg"}/>
                    </div>
                </div>
                <div className="container-fluid">
                    <h1 id="Gallery" className="text-center mt-4 mb-4" style = {{color: '#00587a',fontSize: 45}}>Video Gallery</h1>
                    <Videos/>
                </div>
                <Location/>
                <Footer/>
            </div>
        );
    }
}

export default PressureWashing;