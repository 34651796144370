import React, { Component } from 'react';
import { Fragment } from 'react';
import FloatButton from '../components/FloatButton';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Location from '../components/Location';
import Navbar from '../components/Navbar';
import NavbarItem from '../components/NavbarItem';
import WhyChoose from '../components/WhyChoose';
import Description from '../ServicesComponents/Description';
import Gallery from '../ServicesComponents/Gallery';
import Videos from '../ServicesComponents/Videos';


const des = 
<p>
    <p>
        Construction cleaning is rarely foremost when anyone considers undertaking a remodel or moving out. Instead, when people think about remodeling their workspace or moving to a more spacious office, they usually think about the effort that the renovation takes or the stress of moving everything from one place to the other. Then, you get to enjoy the fabulous new space, right?
    </p>
    <p>
        Hold on. You’ve missed a step. What about the process of cleaning up the incredible mess that’s left behind when the construction crew clears out? Here are some of the benefit of engaging professionals to assist with construction cleanup, allowing you to get back to work sooner rather than later.
    </p>
    <p>
        Sunshine Clean All can handle it all! Whether you are a home owner fighting with renovation dust, or a contractor in need of phased cleaning, our crew of professional cleaners are ready to make your work shine like the sun.
    </p>
</p>
;


const NavbarItems = (
    <Fragment>
        <NavbarItem link={"#Offers"} item={"Offers"}/>
        <NavbarItem link={"#Gallery"} item={"Gallery"}/>
        <NavbarItem link={"#Location"} item={"Contact Info"}/>
    </Fragment>    
);

class ConstructionCleaning extends Component {
    render() {
        return (
            <div>
                <Navbar NavbarItem={ NavbarItems }/>
                <Header/>
                <Description title={"New Construction Cleaning"} url={"https://www.youtube.com/embed/wtfKMkcdhPM"} description={des}/>
                <FloatButton/>
                <WhyChoose/>
                <div className="container-fluid p-3 bg-light">
                    <h1 id="Gallery" className="text-center mt-4" style = {{color: '#00587a',fontSize: 45}}>Gallery</h1>
                    <div className="row">
                        <Gallery imagen={"../images/OfficeImages/office1.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office2.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office3.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office4.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office5.jpg"}/>
                    </div>
                </div>
                <div className="container-fluid">
                    <h1 id="Gallery" className="text-center mt-4 mb-4" style = {{color: '#00587a',fontSize: 45}}>Video Gallery</h1>
                    <Videos/>
                </div>
                <Location/>
                <Footer/>
            </div>
        );
    }
}

export default ConstructionCleaning;