import React, { Component } from 'react';
import "../styles/WhyChoose.css"
import OfferCard from './OfferCard';

class WhyChoose extends Component {
    render() { 
        return (
            <div id="Offers" className="jumbotron why-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="title">O F F E R S</p>
                            
                        </div>
                    </div>
                    <div className="row">
                        <OfferCard img={"../images/offer1.jpg"}/>
                        <OfferCard img={"../images/offer2.jpg"}/>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default WhyChoose;