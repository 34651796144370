import React, { Component } from 'react';

class CheckList extends Component {
    render() { 
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="d-flex align-items-center">
                        <span style={{fontSize: 40,color:'#008891'}}>
                            <i className="far fa-check-circle"></i>
                        </span>
                        <h6 className="ml-2" style={{color: '#0f3057'}}>
                            {this.props.text1}
                        </h6>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="d-flex align-items-center">
                        <span style={{fontSize: 40,color:'#008891'}}>
                            <i className="far fa-check-circle"></i>
                        </span>
                        <h6 className="ml-2" style={{color: '#0f3057'}}>
                            {this.props.text2}
                        </h6>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default CheckList;