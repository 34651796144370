import React, { Component } from 'react';
import "../styles/FloatButton.css"

class FloatButton extends Component {

    
    render() {
        return (
            <div className="fab-container">
                <div className="fabi fab-icon-holder">
                    <i className="far fa-sun"></i>
                </div>
                <ul className="fab-options">
                    <li>
                        <span className="fab-label">Phone</span>
                        <div id="phone" className="fab-icon-holder">
                            <a href="tel:3109254824">
                                <i className="fas fa-phone"></i>
                            </a>
                        </div>
                    </li>
                    <li>
                        <span className="fab-label">Facebook</span>
                        <div id="facebook" className="fab-icon-holder">
                            <a href="https://www.facebook.com/SunShineCleaAll">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </div>
                    </li>
                    <li>
                        <span className="fab-label">YouTube</span>
                        <div id="youtube" className="fab-icon-holder">
                            <a href="https://www.youtube.com/channel/UCL4pC3c6TKHzy5-HWqcy9iQ">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </div>
                    </li>
                    <li>
                        <span className="fab-label">Instagram</span>
                        <div id="instagram" className="fab-icon-holder">
                            <a href="https://www.instagram.com/sunshinecleaningall/">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>          
        );
    }
}
 
export default FloatButton;