import React, { Component, Fragment } from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import FloatButton from '../components/FloatButton';
import Services from '../components/Services';
import ServiceTag from '../components/ServiceTag';
import WhyChoose from '../components/WhyChoose';
import Location from '../components/Location';
import NavbarItem from '../components/NavbarItem';
import Footer from '../components/Footer';
import Videos from '../ServicesComponents/Videos';

const text1 = "We know that businesses have to perform efficiently and properly each and every time, so your office cleaning service must be no different. We also recognize that what we do for you is a reflection on the image and reputation.";
const text2 = "Sunshine Clean all offers commercial and residential window cleaning services. Our crew has the knowledge and experience to confidently tackle any size window-cleaning need.";
const text3 = "Sunshine Clean All can help restore and maintain the original beauty of your carpet with award winning professional carpet cleaning.";
const text4 = "Take advantage of our most popular cleaning package. All your bathrooms, bedrooms and hallways get cleaned as well as your kitchen and living room, with Sunshine Clean All, it’s a worry-free service.";
const text5 = "This service can quickly and effectively remove built-up dirt, sediment, and other debris. This not only helps the areas look beautiful, but it can also reduce wear and tear by getting rid of mold, algae, chalk, insects, weeds, and more.";
const text6 = "Sunshine Clean All can handle it all! Whether you are a home owner fighting with renovation dust, or a contractor in need of phased cleaning, our crew of professional cleaners are ready to make your work shine like the sun.";


const NavbarItems = (
    <Fragment>
        <NavbarItem link={"#Services"} item={"Services"}/>
        <NavbarItem link={"#Offers"} item={"Offers"}/>
        <NavbarItem link={"#Location"} item={"Contact Info"}/>
    </Fragment>    
);



class Home extends Component {
    render() { 
        return (
            <div>
                <Navbar  NavbarItem={ NavbarItems }/>
                <Header/>
                <div className="Services">
                    <Services/>
                    <ServiceTag Icon={"fas fa-hand-sparkles"} Title={"Window Cleaning"} Text={text2} url={"https://www.youtube.com/embed/r16RdGM4HxI"} Icon2={"fas fa-broom"} Title2={"Steam carpet and furniture cleaning"} Text2={text3}  url2={"https://www.youtube.com/embed/ieCNeN8Aztc"}/>
                    <ServiceTag Icon={"far fa-building"} Title={"Disinfecting Office Cleaning"} Text={text1} Icon2={"fas fa-tint"} Title2={"Pressure Washing"} Text2={text5} url={"https://www.youtube.com/embed/Ah6pib9P6s0"} url2={"https://www.youtube.com/embed/wtfKMkcdhPM"}/>
                    <ServiceTag Icon={"fas fa-home"} Title={"House Cleaning"} Text={text4} url={"https://www.youtube.com/embed/KpvkPnfoHms"} Icon2={"fas fa-hard-hat"} Title2={"New Construction Cleaning"} Text2={text6}  url2={"https://www.youtube.com/embed/ieCNeN8Aztc"} />
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                <WhyChoose/>
                <div className="container-fluid">
                    <h1 id="Gallery" className="text-center mt-4 mb-4" style = {{color: '#00587a',fontSize: 45}}>Video Gallery</h1>
                    <Videos/>
                </div>
                <Location/>
                <Footer/>
                <FloatButton/>
            </div>
        );
    }
}
 
export default Home;