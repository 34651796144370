import React, { Component } from 'react';
import CheckList from './CheckList';
import '../styles/Description.css'

class Description extends Component {
    render() { 
        return (
            <div className="container-fluid description-section pb-5">
                <div className="container">
                <div className="row justify-content-center align-items-center pt-5">
                    <div className="col-md-7">
                        <h1 style = {{color: '#00587a',fontSize: 50}}>
                            {this.props.title}
                        </h1>
                        <p>
                            {this.props.description}
                        </p>
                
                        <CheckList text1={"Highly Rated & Esteemed"} text2={"Trusted Professionals"}/>
                        <CheckList text1={"Insured and Bonded"} text2={"Quality Service"}/>
                        
                    </div>
                    <div className = "col-md-5 mt-5">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item" title={this.props.title} width="560" height="315" src={this.props.url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        );
    }
}
 
export default Description;