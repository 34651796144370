import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_l26uxzg', 'template_o3emzdj', e.target, 'user_RSdvYtoUhj5xUGajqzObA')
      .then((result) => {
        Swal.fire(
            'Message Sent!',
            result.text,
            'success'
          )
      }, (error) => {
        Swal.fire(
            'Error, please try again.',
            error.text,
            'error'
          )
      });

    e.target.reset();
    Swal.fire({
        title: 'Please Wait',
        allowOutsideClick: false
    })
    Swal.showLoading()      
  }

class Form extends Component {
    render() {
        return (
            <form onSubmit={sendEmail}>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputName4">Name</label>
                        <input type="text" className="form-control" id="inputName4" name="name"/>
                    </div>
                    <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Email</label>
                        <input type="email" className="form-control" id="inputEmail4" name="email"/>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="inputAddress">Address</label>
                    <input type="text" className="form-control" id="inputAddress" placeholder="1234 Main St" name="address"/>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputCity">City</label>
                        <input type="text" className="form-control" id="inputCity" name="city"/>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputNumber">Phone</label>
                        <input type="number" className="form-control" id="inputNumber" name="phone"/>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="inputSubject">Subject</label>
                    <input type="text" className="form-control" id="inputSubject" name="subject"/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Message</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" name="message"></textarea>
                </div>
                <button type="submit" className="btn btn-success d-flex flex-center">Send Message</button>
            </form>
        );
    }
}
 
export default Form;