import React, { Component, Fragment } from 'react';
import FloatButton from '../components/FloatButton';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Location from '../components/Location';
import Navbar from '../components/Navbar';
import NavbarItem from '../components/NavbarItem';
import WhyChoose from '../components/WhyChoose';
import Description from '../ServicesComponents/Description';
import Gallery from '../ServicesComponents/Gallery';
import Videos from '../ServicesComponents/Videos';

const des = "We know that businesses have to perform efficiently and properly each and every time, so your office cleaning service must be no different. We also recognize that what we do for you is a reflection on the image and reputation. You expect prompt and reliable no-hassle service from your office cleaning service and we have been delivering that to our customers plus much more throughout the Los Angeles area. We are attentive down to the small details and we treat your business with personal care, because we want your staff and your customers to appreciate that you are looking after their health and environment. That’s the difference that Sunshine Clean All brings to your office cleaning service.";

const NavbarItems = (
    <Fragment>
        <NavbarItem link={"#Offers"} item={"Offers"}/>
        <NavbarItem link={"#Gallery"} item={"Gallery"}/>
        <NavbarItem link={"#Location"} item={"Contact Info"}/>
    </Fragment>    
);



class OfficeCleaning extends Component {
    render() { 
        return (
            <div>
                <Navbar  NavbarItem={ NavbarItems }/>
                <Header/>
                <Description title={"Disinfecting Office Cleaning"} url={"https://www.youtube.com/embed/9IUi2s26rWU"} description={des}/>
                <WhyChoose/>
                <FloatButton/>
                <div className="container-fluid p-3 bg-light">
                    <h1 id="Gallery" className="text-center mt-4" style = {{color: '#00587a',fontSize: 45}}>Gallery</h1>
                    <div className="row">
                        <Gallery imagen={"../images/OfficeImages/office1.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office2.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office3.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office4.jpg"}/>
                        <Gallery imagen={"../images/OfficeImages/office5.jpg"}/>
                    </div>
                </div>
                <div className="container-fluid">
                    <h1 id="Gallery" className="text-center mt-4 mb-4" style = {{color: '#00587a',fontSize: 45}}>Video Gallery</h1>
                    <Videos/>
                </div>
                <Location/>
                <Footer/>
            </div>
        );
    }
}
 
export default OfficeCleaning;