import React, { Component } from 'react';
import "../styles/Header.css"
import ContactModal from './ContactModal';

class Header extends Component {

    render() { 
        return (
            <div id="Header">
                <div id="landing-page-js" className="jumbotron landing-page d-flex align-items-center justify-content-center">
                    <div className = "intro d-flex flex-column">
                        <img className="img-fluid align-self-center" src = "images/sunshinelogo2.png" alt="Logo"/>
                        <br></br>
                        {this.props.location}
                        <button type="button" className="btn button-contact btn-outline-warning align-self-center" data-toggle="modal" data-target="#contactModal"> CONTACT US </button>
                    </div>
                </div>
                <ContactModal idModal={"contactModal"}/>
            </div>
        );
    }
}
 
export default Header;