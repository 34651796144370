import React, { Component } from 'react';

class Videos extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-4">
                    <div className="embed-responsive embed-responsive-16by9">
                        <video className="embed-responsive-item" width="750" height="500" controls >
                            <source src={process.env.PUBLIC_URL + '/videos/houseCleaning.mp4'} type="video/mp4"/>
                        </video>
                    </div>
                </div>
                <div className="col-md-4 mt-4 mt-md-0">
                    <div className="embed-responsive embed-responsive-16by9">
                        <video className="embed-responsive-item" width="750" height="500" controls >
                            <source src={process.env.PUBLIC_URL + '/videos/officeVacuum.mp4'} type="video/mp4"/>
                        </video>
                    </div>
                </div>
                <div className="col-md-4 mt-4 mt-md-0">
                    <div className="embed-responsive embed-responsive-16by9">
                        <video className="embed-responsive-item" width="750" height="500" controls >
                            <source src={process.env.PUBLIC_URL + '/videos/windowOfficeCleaning.mp4'} type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default Videos;