import React from 'react';
import {Switch,Route} from 'react-router-dom';
import CarpetCleaning from './Sites/CarpetCleaning';
import ConstructionCleaning from './Sites/ConstructionCleaning';
import Home from './Sites/Home';
import HouseCleaning from './Sites/HouseCleaning';
import OfficeCleaning from './Sites/OfficeCleaning';
import PressureWashing from './Sites/PressureWashing';
import WindowCleaning from './Sites/WindowCleaning';

const Routes = () => {
    return (
        <Switch>
            <Route exact path = '/' component={Home}/>
            <Route path = '/WindowCleaning' component={WindowCleaning} />
            <Route path = '/DisinfectingOfficeCleaning' component={OfficeCleaning} />
            <Route path = '/Steamcarpetandfurniturecleaning' component={CarpetCleaning} />
            <Route path = '/PressureWashing' component={PressureWashing} />
            <Route path = '/HouseCleaning' component={HouseCleaning} />
            <Route path = '/NewConstructionCleaning' component={ConstructionCleaning} />
        </Switch>
    );
}
 
export default Routes;