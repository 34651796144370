import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/ServiceIcon.css"
import VideoModal from './VideoModal';

const ServiceTag = (props) => {
    return (
        <div className="container d-flex">
            <div className="row mt-5">
                <div className="col-md-1 text-center mb-5">
                    <span id="ServiceIcon">
                        <i className={props.Icon}></i>
                    </span>
                </div>
                <div className="col-md-5">
                    <h4 className="inline tagTitle">
                        {props.Title}
                    </h4>
                    <p className="services-bar"></p>
                    <p>
                        {props.Text}
                    </p>
                    <button type="button" className="btn btn-outline-primary align-self-center" data-toggle="modal" data-target={"#" + props.Title.replace(/\s+/g, '')}>Watch Video</button>
                    <Link to={"/" + props.Title.replace(/\s+/g, '')}>
                        <button type="button" className="btn btn-outline-secondary ml-2">More Info</button>
                    </Link>
                    
                    <VideoModal idModal={props.Title.replace(/\s+/g, '')} url={props.url} modalTitle={props.Title}/>
                </div>
                <div className="col-md-1 text-center mb-4">
                    <span id="ServiceIcon">
                        <i className={props.Icon2}></i>
                    </span>
                </div>
                <div className="col-md-5">
                    <h4 className="inline tagTitle">
                        {props.Title2}
                    </h4>
                    <p className="services-bar"></p>
                    <p>
                        {props.Text2}
                    </p>
                    <button type="button" className="btn btn-outline-primary align-self-center" data-toggle="modal" data-target={"#" + props.Title2.replace(/\s+/g, '')}>Watch Video</button>
                    <Link to={"/" + props.Title2.replace(/\s+/g, '')}>
                        <button type="button" className="btn btn-outline-secondary ml-2">More Info</button>
                    </Link>
                    <VideoModal idModal={props.Title2.replace(/\s+/g, '')} url={props.url2} modalTitle={props.Title2}/>
                    <VideoModal/>
                </div>
            </div>
        </div>
    );
}
 
export default ServiceTag;