import React, { Component } from 'react';

class OfferCard extends Component {
    render() { 
        return (
            <div className="card offer-card">
                <img src={this.props.img} className="card-img-top" alt="Offer"/>
            </div>
        );
    }
}
 
export default OfferCard;