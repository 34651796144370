import React, { Component } from 'react';
import IconText from './IconText';

class Location extends Component {
    render() { 
        return (
            <div id="Location" className="container-fluid mt-5 mb-5 ">
                <div className="row">
                    <div className="col-md-6">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe title="Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d711493.9421631016!2d-118.77707159116683!3d34.066143722614854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c75ddc27da13%3A0xe22fdf6f254608f4!2sLos%20%C3%81ngeles%2C%20California%2C%20EE.%20UU.!5e0!3m2!1ses!2sgt!4v1601604462517!5m2!1ses!2sgt" width="600" height="450" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4">
                        <div className="text-center">
                            <h2>CONTACT INFO</h2>
                        </div>
                        <h5 className="mt-2 badge badge-primary text-wrap">Phone:</h5>
                        <a href="tel:3109254824">
                            <IconText icon={"fas fa-phone-alt"} text={"Main: (310) 925-4824"}/>
                        </a>
                        <h5 className="mt-3 badge badge-primary text-wrap">Location and Schedule</h5>
                        <IconText icon={"fas fa-map-marker-alt"} text={"Los Angeles, CA"}/>
                        <IconText icon={"far fa-clock"} text={"Open 24/7"}/>
                        <IconText icon={"fas fa-globe-americas"} text={"Service Area: Los Angeles, Santa Monica, Culver City, Beverly Hills, Malibu, Pacific Palisades, South Bay, Long Beach and Palos Verdes, CA."}/>
                        <h5 className="mt-3 badge badge-primary text-wrap">E-mail:</h5>
                        <IconText icon={"fas fa-envelope"} text={"info@sunshinecleaningallca.com"}/>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-primary align-self-center mt-2" data-toggle="modal" data-target="#contactModal"> CONTACT US </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Location;