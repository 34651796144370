import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "../styles/Navbar.css";



class Navbar extends Component {

    render() { 
        return ( 
            <section id="header">
                <nav className="navbar navbar-expand-lg fixed-top">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to={"/"}>
                                Home
                            </Link>
                        </li>
                        {this.props.NavbarItem}
                        </ul>
                    </div>
                </nav>
            </section>
         );
    }
}
 
export default Navbar;